import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, ISSUE_DOCUMENTS_TYPES, UNSUPPORTED_MIME_TYPE } from "@/constants"
import { downloadFile, generateMD5ForFile } from "@/utils"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-documents"

export default {
  /**
   * This action will load issue documents.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadIssueDocuments(context, payload) {
    context.commit("setLoadingIssueDocuments", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueDocumentsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueDocumentsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueDocuments", getIssueDocumentsResponse.data)
      } else {
        context.commit("setIssueDocuments", getIssueDocumentsResponse.data)
      }
    } else {
      // something went wrong while getting issueDocuments
    }

    context.commit("setLoadingIssueDocuments", false)
  },

  /**
   * This action downloads file associated with issue document
   * @param {*} context is the store.
   * @param {*} payload issue document whose associated file has to be downloaded
   */
  async downloadFile(context, { id, name, md5 }) {
    context.commit("setFailedDocumentDownloadId", null)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDownloadURLResponse = await httpClient.get(`/${id}/download-url`)
    if (getDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      try {
        await downloadFile(getDownloadURLResponse.data, md5, name)
      } catch (error) {
        context.commit("setFailedDocumentDownloadId", id)
      }
    }
  },

  async addIssueDocument(context, { issueId, file }) {
    context.commit("setAddingIssueDocument", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const md5 = await generateMD5ForFile(file)
    let type  = file.type

    if (file.name.toLowerCase().endsWith(ISSUE_DOCUMENTS_TYPES.MSG_EXTENSION) && type === "") {
      type = UNSUPPORTED_MIME_TYPE.MSG
    }

    const postIssueDocumentResponse = await httpClient.post(undefined, {
      issueId,
      md5,
      name: file.name,
      size: file.size,
      type
    })

    if (postIssueDocumentResponse.status === HTTP_STATUS_CODE.OK) {
      const issueDocument = postIssueDocumentResponse.data
      context.dispatch("accessControl/loadLoggedInUserAccess", [{
        policies   : ["IssueDocument remove"],
        resourceIds: [issueDocument.id]
      }], { root: true })

      const uploadUrlResponse = await httpClient.get(`/${issueDocument.id}/upload-url`)

      if (uploadUrlResponse.status === HTTP_STATUS_CODE.OK) {
        const form = new FormData()
        Object.keys(uploadUrlResponse.data.fields).forEach(key =>
          form.append(key, uploadUrlResponse.data.fields[key]))
        form.append("file", file)

        const headers = {
          "Content-MD5": Buffer.from(md5, "hex").toString("base64")
        }

        const response = await fetch(uploadUrlResponse.data.url, {
          headers,
          method: "POST",
          body  : form
        })

        if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
          context.commit("updateIssueDocuments", [issueDocument])
        } else {
          context.dispatch("removeIssueDocument", issueDocument)
        }
      } else {
        context.dispatch("removeIssueDocument", issueDocument)
      }
    }
    context.commit("setAddingIssueDocument", false)
  },

  async removeIssueDocument(context, documentToBeRemoved) {
    context.commit("setIssueDocumentRemoved", false)
    context.commit("setRemovingIssueDocument", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteIssueDocumentResponse = await httpClient.delete(`/${documentToBeRemoved.id}`)

    if (deleteIssueDocumentResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeIssueDocument", documentToBeRemoved)
      context.commit("setIssueDocumentRemoved", true)
    }
    context.commit("setRemovingIssueDocument", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssueDocuments", new Array())
    context.commit("setLoadingIssueDocuments", undefined)
    context.commit("setAddingIssueDocument", undefined)
    context.commit("setRemovingIssueDocument", undefined)
    context.commit("setIssueDocumentRemoved", undefined)
    context.commit("resetAccessInformation")
  }
}